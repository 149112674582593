import * as bootstrap from 'npm:bootstrap'

// Google Analytics
(function () {
  window.dataLayer = window.dataLayer || []
  function gtag () { dataLayer.push(arguments) }
  gtag('js', new Date())
  gtag('config', 'G-TW64H3DNEL')
})();

// menu
(function () {
  const menu = document.getElementById('menu')
  if (!menu) return
  const offcanvas = new bootstrap.Offcanvas(menu)
  for (const item of menu.querySelectorAll('a.list-group-item-action')) {
    item.addEventListener('click', () => offcanvas.hide())
  }
  const btn = document.getElementById('openMenuBtn')
  btn.addEventListener('click', () => offcanvas.show())
})();

// nav (asg-school)
(function () {
  const nav = document.querySelector('.js-asg-school-nav')
  if (!nav) return
  const toggleButton = nav.querySelector('.js-asg-school-nav-toggle')

  toggleButton.addEventListener('click', () => nav.classList.toggle('is-open'))

  for (const item of nav.querySelectorAll('a')) {
    item.addEventListener('click', () => nav.classList.remove('is-open'))
  }
})();

// form.contactus
(function () {
  const blacklist = [
    /クチコミワークス/,
    /エン・ジャパン/,
    /株式会社LIG/,
    /シェルパーズ/,
    /ネタもと/,
    /イーコネクション/,
    /SOKUDAN/,
    /Nexus project/,
    /INSTANTROOM/,
    /AimyTech/,
    /株式会社LTB/,
    /株式会社アンドストーリー/,
    /Anotherworks/,
    /RedotPay/,
    /エルマーケ/,
    /レイクルー/,
    /ランサーズ株式会社/,
    /イークラウド株式会社/,
    /合同会社Nerds/,
    /株式会社meetX/,
    /TechSuite株式会社/,
    /M&A総合研究所/,
    /株式会社メタップスホールディングス/,
    /CCテクノサービス/,
    /LENIS合同会社/,
    /株式会社ジェイ・キャスト/,
    /MM EDIT/,
    /DXO株式会社/,
    /リスナーズ株式会社/,
    /オフィス清家/,
    /ガイアコミュニケーションズ/,
    /株式会社UPTORY/,
    /アウル株式会社/,
    /コトバ執筆社/,
    /ホワイトペーパー/,
    /インフルエンサー/,
    /芸能人/,
    /口コミサイト/,
    /低評価の口コミ/,
    /クチコミ対策/,
    /誹謗中傷/,
    /風評被害/,
    /金融ロボット/,
    /経済的自立/,
    /金融ツール/,
    /金持ち/,
    /お金が必要/,
    /もう仕事を探す必要はありません/,
    /アフィリエイト/,
    /リード獲得/,
    /暗号資産/,
    /LINE公式アカウント/,
    /startupers\.se/,
    /dkworld\.de/,
    /seamonkey\.es/,
    /中古パソコン/,
    /YouTube動画上位検索/,
    /新規アポイント獲得/,
    /ポスティングの強み/,
    /ポスティング会社/,
    /人材育成/,
    /クリプトアドバイザー/,
    /バーチャルキャラクター/,
    /タレントキャスティング/
  ]

  const forms = document.querySelectorAll('form.contactus')
  for (const form of forms) {
    const btn = form.querySelector('button')
    const status = form.querySelector('p.status')
    form.addEventListener('submit', function (ev) {
      btn.disabled = true
      ev.preventDefault()

      // ブラックリストは submit しない
      const message = form.querySelector('[name=message]').value
      for (const re of blacklist) {
        if (re.exec(message)) {
          status.innerHTML = 'お問い合わせを送信しました。'
          form.reset()
          btn.disabled = false
          status.classList.remove('d-none')
          return
        }
      }

      const data = new FormData(ev.target)
      fetch(ev.target.action, {
        method: form.method,
        body: data,
        headers: { Accept: 'application/json' }
      }).then(async response => {
        if (response.ok) {
          status.innerHTML = 'お問い合わせを送信しました。'
          form.reset()
        } else {
          const { errors } = await response.json()
          if (errors && errors[0].message === 'should be an email') {
            status.innerHTML = 'メールアドレスが間違っています。'
          } else {
            status.innerHTML = 'エラーが発生しました。'
          }
        }
        status.classList.remove('d-none')
      }).catch(err => {
        alert(err)
      }).finally(() => {
        btn.disabled = false
      })
    })
  }
})();

// Clarity
(function (c, l, a, r, i, t, y) {
  c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) }
  t = l.createElement(r); t.async = 1; t.src = 'https://www.clarity.ms/tag/' + i
  y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y)
})(window, document, 'clarity', 'script', 'kxhh4xxait')
